/*
  login page styles
*/
.siteHeader {
   font-size: 24px;
   font-weight: 800;
   color: white;
   margin-top: 12%;
}

.helpHeader {
    
   font-weight: bold;
   text-align: right;
   background-color: #D3D3D3;   
   color:#0d0d0e; 
   cursor: pointer;
  width:100px; 
  height:20px;
  text-align:center;
  padding:0px 15px;
  margin-left: 0px;
    float:right;
    margin-bottom:20px;
    position: absolute;
    top: 10px;
    right: 10px;
    image-rendering: auto;  

}
.loginContent {
   display: flex;
   align-items: center;
   flex-direction: column;
   padding: 20px;
   max-width: 500px;
   margin: auto;
   border: 1px solid #0173d4;
   border-top-left-radius: 0.5rem;
   border-top-right-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   background-color: white;
   min-height: 300px;
}
.loginForm {
   width: 90%;
}
.loginTextHeader {
   font-size: 24px;
   font-weight: bold;
   color: #0173d4;
}
.loginBtnText {
   font-size: 16px;
   color: #fff;
   text-align: center;
   background-color: #0173d4;
   border-top-left-radius: 2rem;
   border-top-right-radius: 2rem;
   border-bottom-right-radius: 2rem;
   border-bottom-left-radius: 2rem;
   border: 0.5px solid;
   border-color: #0173d4;
   border-collapse: collapse;
   padding-top: 0.1rem;
   padding-bottom: 0.1rem;
   width: 40%;
}
.loginBtnText:hover {
   outline: none;
}
.loginBtnText:focus {
   outline: none;
}
.logoutBtnText {
   font-size: 12px;
   color: #0173d4;
   background-color: white;
   border: none;
   font-weight: bold;
   padding: 0%;
   text-align: center;
   vertical-align: middle;
}
.logoutBtnText:hover {
   text-decoration: none;
}
.placeHolderText {
   font-family: FontAwesome;
}
.btnContainer {
   width: 100%;
}
.spinnerBorder {
   width: 2.5rem;
   height: 2.5rem;
}
/*
  Search page styles
*/

.contentBackground {
   background-color: white;
}
.searchContent {
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 5px;
   max-width: 500px;
   margin: auto;
   margin-top: 8%;
}
.searchTable {
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 5px;
   margin: auto;
   margin-top: 2%;
}
.searchBtnText {
   font-size: 16px;
   color: #fff;
   text-align: center;
   background-color: #09f;
   border-top-right-radius: 2rem;
   border-bottom-right-radius: 2rem;
   border: 0.5px solid;
   border-color: #09f;
   border-collapse: collapse;
   padding-top: 0.1rem;
   padding-right: 3rem;
   padding-bottom: 0.1rem;
   padding-left: 3rem;
}
.searchBtnText:hover {
   outline: none;
}
.searchBtnText:focus {
   outline: none;
}
.searchBox {
   border-top-left-radius: 2rem;
   border-bottom-left-radius: 2rem;
}

/*
  Searched Data display styles
*/
.searchDataContainer {
   background-color: white;
   border: 0.1px solid;
   border-color: #0640a3;
   border-collapse: collapse;
}
.tableHeaderRow {
   background-color: #0640a3;
}
.labels {
   color: #fff;
}
.tabRow {
   border-top: 1px solid #0640a3;
}

/*
  Fpp page styles
*/
.leftBorder {
   border-left: 1px solid #0173d4;
}
.dataCol {
   /* margin-bottom:500px;
  padding-bottom: 99999px; */
   min-height: 85vh;
   overflow: hidden;
}
.banner {
   min-height: 10vh;
}
.accordionStyle {
   background-color: #003daf;
   color: white;
   height: 35px;
}

.accordIconStyle {
   float: right;
}
#pageheaders {
   font-weight: bold;
   text-align: left;
}
.pageborder {
   /* margin: 0 0 20 0; */
   border-bottom: 1px solid #bbbbbb;
   margin: auto;
}
.table thead th,
td {
   vertical-align: top;
   border: none;
}

.table thead th {
   text-align: left;
}

.table tbody td {
   vertical-align: middle;
}
.collapsing {
   -webkit-transition: none;
   transition: 0.1s;
}
.alertMsg {
   font-size: 1.2rem;
   font-weight: 400;
   color: #a00;
   text-align: center;
}
.alertMsg1 {
   font-weight: bold;
   color: #a00;
}
.successMsg {
   font-size: 1.2rem;
   font-weight: 400;
   color: #0a1c;
   text-align: center;
}
.successMsgVendor {
   font-size: 1.2rem;
   font-weight: bold;
   color:black;
   text-align: center;
}
.filteredDataMsg {
   font-size: 1.2rem;
   font-weight: 400;
   color: #003daf;
   text-align: center;
}
.highlightFields {
   border: 1px solid #a00;
}
.colRow {
   border-top: 1px solid white;
}
.leftArea {
   margin-left: 10px;
}
.nav-link {
   padding-bottom: 0.01rem;
   padding-right: 0px;
   padding-left: 0px;
}
.nav-link:hover {
   color: white;
}
.notice {
   font: message-box;
   text-align: center;
   background-color: wheat;
   height: 22px;
   margin-top: 1px;

}

@media (min-width: 1100px) and (max-width: 1200px) {
.notice {
height: 30px;
   }
}
@media (min-width: 100px) and (max-width: 1099px) {
   .notice {
   height: 40px;
   }
}
.userIconStyle {
   float: right;
   color: #0173d4;
   margin-right: 2%;
   margin-top: 2%;
   font-size: 12px;
   font-weight: 600;
}
.popoverText {
   color: #0173d4;
   font-size: 12px;
}

.card-body {
   flex: 1 1 auto;
   padding: 0.5rem;
}
.pointer {
   cursor: pointer;
}
.sideNavButton {
   color: #fff;
   vertical-align: middle;
   text-align: left;
   background: none;
   border: none;
   margin-top: 1px;
   margin-bottom: 1px;
   vertical-align: middle;
   text-align: left;
   padding-left: 15px;
   padding-top: 0px;
   padding-bottom: 0px;
}
.sideNavButton1 {
   color: #fff;
   vertical-align: middle;
   text-align: left;
   background: none;
   border: none;
   margin-top: 1px;
   margin-bottom: 1px;
   vertical-align: middle;
   text-align: left;
   /* padding-left: 15px; */
   padding-top: 0px;
   padding-bottom: 0px;
}
.sideNavButton:hover {
   outline: none;
   background: #09f;
}
.sideNavButton:focus {
   outline: none;
   background: #09f;
}

.leftSection {
   padding-left: 0px;
   padding-right: 0px;
}
.sideNav {
   position: fixed;
   height: 75%;
   /* overflow-y: scroll; */
   padding-left: 0px;
   padding-right: 0px;
   overflow-y: auto;
   overflow-x: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
/* .sideNav::-webkit-scrollbar {
  display: none;
} */

.bomFontClass {
   font-weight: bold;
}
.expandCollapseButton {
   color: #fff;
   vertical-align: middle;
   text-align: left;
   background: #09f;
   border: none;
   margin-top: 1px;
   margin-bottom: 1px;
   vertical-align: middle;
   border: 0.5px solid;
   border-color: #09f;
   border-collapse: collapse;
   padding-top: 0.1rem;
   padding-bottom: 0.1rem;
}

.animated-background {
   animation-duration: 4.5s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
   animation-name: mainplaceHolderShimmer;
   background: #f6f7f8;
   background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 30%);
   height: 40px;
}
@keyframes mainplaceHolderShimmer {
   0% {
      background-position: -100px 0;
   }
   100% {
      background-position: 1000px 0;
   }
}
@keyframes placeHolderShimmer {
   0% {
      background-position: -100px 0;
   }
   100% {
      background-position: 120px 0;
   }
}
.leftSectionScreen {
   animation-duration: 3.5s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
   animation-name: placeHolderShimmer;
   background: #f6f7f8;
   background: linear-gradient(to right, #eeeeee 12%, #dddddd 33%, #eeeeee 93%);
   width: 100%;
   height: 100%;
}
.accordionDiv {
   padding: 10px;
   margin-top: 5px;
   margin-bottom: 5px;
   border: 1px solid lightgrey;
   border-collapse: collapse;
   border-top-left-radius: 0.3rem;
   border-top-right-radius: 0.3rem;
   border-bottom-right-radius: 0.3rem;
   border-bottom-left-radius: 0.3rem;
}

.checkboxStyle {
   color: #fff;
   vertical-align: middle;
   text-align: left;
   background: none;
   border: none;
   margin-top: 1px;
   margin-bottom: 1px;
   vertical-align: middle;
   text-align: left;
   margin-left: 15px;
   padding-top: 0px;
   padding-bottom: 0px;
}

.checkboxdrpStyle {
   vertical-align: middle;
   text-align: left;
   background: none;
   border: none;
   margin-top: 1px;
   margin-bottom: 1px;
   vertical-align: middle;
   text-align: left;
   margin-left: 15px;
   padding-top: 0px;
   padding-bottom: 0px;
}

.activeButton {
   margin: 0;
   width: 100%;
   padding: 0px;
   background-color: #09f;
   border-radius: 0%;
   border: 0px;
   color: white;
   border: 0.5px solid #09f;
   border-collapse: collapse;
}
.inactiveButton {
   margin: 0;
   width: 100%;
   padding: 0px;
   background-color: #003daf;
   border-radius: 0%;
   border: 0px;
   color: white;
   border: 0.5px solid lightgrey;
   border-collapse: collapse;
}
.formGroup {
   margin-bottom: 0rem;
}

.errorMsgStyle {
   color: #0173d4;
   margin-right: 2%;
   margin-top: 2%;
   font-size: 13px;
   font-weight: 600;
   text-align: center;
}

.fa_custom {
   color: #0099cc;
}

.dropdown-menu {
   min-width: 18rem;
}

.searchBtnText_copy {
   font-size: 16px;
   color: #fff;
   text-align: center;
   background-color: #09f;
   border-top-right-radius: 2rem;
   border-bottom-right-radius: 2rem;
   border: 0.5px solid;
   border-color: #09f;
   border-collapse: collapse;
   padding-top: 0.1rem;
   padding-right: 1.5rem;
   padding-bottom: 0.1rem;
   padding-left: 1.5rem;
}

.searchBtnText_copy:hover {
   background-color: #007bff;
   outline: none;
}
.searchBtnText_copy:focus {
   background-color: #007bff;
   outline: none;
}

.radbtnClass {
   font-size: 14px;
   color: #fff;
   margin-top: 1rem;
   padding-bottom: 0.5rem;
   padding-left: 1.4rem;
}

.tooltip-inner {
   /* background-color:white; */
   color: black;
   border-width: 1px;
   /* border-color: rgb( 37, 207, 187 ); */
   border-color: #007bff !important;
   border-style: solid;
   /* background-color: rgb( 219, 242, 239 ); */
   background-color: white;
   min-width: 15rem;
   max-width: 20rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
   border-bottom-color: #007bff !important;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
   border-right-color: #007bff !important;
}

.childRow {
   border-bottom: 1px solid #dee2e6;
}

.vendorTable {
   width: 100%;
   overflow: auto;
} 

.vendorOptions {
   border: 1px solid black;
}

#infoTab {
   width: 100%;
   overflow: auto;
}

#infoTab th {
   text-align: left;
   color: black;
}

#paginTab ul {
   margin-inline-end: 0px;
   margin-inline-start: 0px;
   margin-block-start: 0px;
   display: flex;
   padding-left: 0;
   list-style: none;
   border-radius: 0.25rem;
   float: right;
}
.searchScrollbar {
   overflow-x: auto;
   padding-left: 15px;
   padding-right: 15px;
}

.bomStyle {
   border-width: "2px";
   margin-top: "5px";
}
.modalTitle {
   color: #fff;
   text-align: center;
   background-color: #003daf;
   align-items: center;
}

.fixTableHead {
   overflow-y: auto;
   height: 55vh;
}
.fixTableHead thead th {
   position: sticky;
   top: -1px;
   z-index: 1020;
   background-color: white;
}

.hr {
   display: block;
   margin-left: 0px;
   margin-right: 0px;
   border-style: inset;
   border-width: 0.5px;
   border-color: #0173d4;
}

.reportLabels {
   color: #0173d4;
   font-weight: bold;
}
.reportLabels1 {
   color: #0173d4;
   font-weight: bold;
   font-size: 20px;
   margin-top: 100px;
}
.reportLabels2 {
   color: #0173d4;
   font-weight: bold;
   font-size: 20px;
   font-weight: 750;
   /* margin-top: 100px; */
}
.Modalheading {
      position: sticky;
      top:-1;
      height:25px;
      background-color:#003daf;
      color: white;
      font-Weight:600;
      font-Size:15px; 
      padding-Left:20px;
      margin-Top:4px;
      margin-Bottom:5px;
      /* margin-Right:17px */
}


.reportForm {
   border: 1px solid #0d0d0e;
   border-collapse: collapse;
}

.reportOptions {
   border: 1px solid #0173d4;
}

#dropdownAction > .dropdown-menu.show {
   min-width: 80px !important;
   width: 150px !important;
   transform: translate(-40px, 18px) !important;
   background-color: #f3f3f3;
}

#actionsDropdown > .dropdown-menu.show {
   min-width: 80px !important;
   width: 176px !important;
   transform: translate(-40px, 18px) !important;
   background-color: #f3f3f3;
}

.custom-switch-container {
   display: flex;
   align-items: center;
}

.switch-label {
   margin-top: 3px;
}

.dropdownActionItem {
   font-size: 12px;
   color: #0173d4 !important;
   border: none;
   font-weight: bold;
   padding: 0%;
   text-align: left;
   vertical-align: middle;
   padding: 2px 5px;
}
.subLabel {
   margin-top: -10px !important;
   color: #0173d4;
   font-weight: bold;
}

#template_summ_tab th {
   text-align: left;
   color: white;
   background-color: #007bff;
}

.lineBreak {
   word-break: break-all;
}

.dropdownBtnItem {
   font-size: 12px;
   color: #0173d4 !important;
   font-weight: bold;
   padding: 2px 5px;
}
.dropdown-menu[aria-labelledby="dropdown-basic-button"].show {
   margin-top: 2px !important;
   display: block !important;
   min-width: 10rem !important;
   background-color: #f3f3f3 !important;
}
.download-table-xls-button {
   width: 150px;
   height: 33px;
   color: #fff;
   background-color: #007bff;
   border-color: #007bff !important;
   border: 1px solid transparent;
   border-radius: 0.25rem;
   /* border-collapse: collapse; */
   padding: 0.375rem 0.75rem;

   display: inline-block;
   font-weight: 400;
   text-align: center;
   vertical-align: middle;
   user-select: none;
   font-size: 1rem;
   transition: color 0.15s;
}
.download-table-xls-button:focus,
.download-table-xls-button:active {
   border-color: #007bff !important;
   border: 1px solid transparent;
}

@media screen and (min-width: 768px) {
   .action {
      flex: 0 0 8.333333%;
      max-width: 100%;
   }
}
@media screen and (max-width: 767px) {
   .action {
      flex: 0 0 8.333333%;
      max-width: 100%;
   }
}

@media screen and (min-width: 768px) {
   .template {
      flex: 0 0 8.333333%;
      max-width: 100%;
   }
}
@media screen and (max-width: 767px) {
   .template {
      flex: 0 0 8.333333%;
      max-width: 100%;
   }
}

@media screen and (min-width: 768px) {
   .paginationSub {
      flex: 0 0 8.333333%;
      max-width: 100%;
   }
}
@media screen and (max-width: 767px) {
   .paginationSub {
      flex: 0 0 8.333333%;
      max-width: 100%;
   }
}


.DSTable tbody {
   overflow-y: scroll;
   overflow-x: hidden;
   height: 313px;
   display: block;
}
.DSTable td, th {
   height: 25px;
   overflow:hidden;
}

.searchType {
   width: 100% !important;
}
  
#impInput{
   color: #a00505;
}

.my-modal {
   width: 75vw ;   /* Occupy the 75% of the screen width */
   max-width: 75vw;
}
.my-modal2 {
   width: 80vw ;   /* Occupy the 75% of the screen width */
   max-width: 80vw;
   
}

.modal-open {
   padding-right: 0px !important;
}

#headcontainer {
   margin: 0px !important;
   padding: 0px 15px !important;
}

.ColGrow {
   flex-grow: 0;
}

.ColGrow Button {
   margin-top: 60px;
}
.ColGrow1  {
   margin:auto;
  }
.ColGrow2 {
   margin:auto;
  }
  


.BusinessList {
   overflow-y: scroll;
   overflow-x: hidden;
   height: 200px;
   width: 200px;
   display: block;
}
.BusinessListpreferance{
   overflow-y: scroll;
   overflow-x: hidden;
   height: 200px;
   /* width: 200px; */
   display: block;
}

.tableFixHead {
   overflow-y:auto;
   max-height: 70vh;

 }
 .tableFixHead table{
   width:100%;
   min-height:13vh;
 }

 .tableFixHead thead tr th {
   position: sticky;
   top: 0;
   background: #eee;
   padding-right: 10px;
 }

 .tableFixHead tbody tr td {
   padding-right: 10px;
 }

 #tab-separtor td{
   border-bottom: 1px solid #eee;
   border-collapse: collapse;
}

 .performanceTableFixHead {
   overflow-y:auto;
   max-height: 90vh;

 }
 .performanceTableFixHead table{
   width:100%;
   min-height:13vh;
 }

 .performanceTableFixHead thead tr th {
   position: sticky;
   top: 0;
   background: #eee;
   padding-right: 10px;
 }
 .performanceTableFixHead tbody tr td {
   padding-right: 10px;
 }

.dropdown #options{
   color: #fff;
   vertical-align: middle;
   text-align: left;
   background: #09f;
   margin-top: 1px;
   margin-bottom: 1px;
   border: 0.5px solid;
   border-color: #09f;
   border-collapse: collapse;
   padding-top: 0.1rem;
   padding-bottom: 0.1rem;

 }
 .designParamPlus{
   background-color: rgb(240, 232, 232); /* Green */
   border: 1px solid rgb(192, 188, 188);
   border-collapse: collapse;
   border-top-left-radius: 0.3rem;
   border-top-right-radius: 0.3rem;
   border-bottom-right-radius: 0.3rem;
   border-bottom-left-radius:0.3rem;
   color: rgb(105, 103, 103);
   padding: 3px 3px 3px 3px;
   text-align: center;
   display: inline-block;
   margin: 4px 4px;
   cursor: pointer;
   transition-duration: 0.2s;
   box-shadow: 0 4px 4px 0 rgba(156, 149, 149, 0.2), 0 4px 4px 0 rgba(156, 149, 149, 0.2);
 }

 .keepSpace {
   word-wrap: break-word;
   word-break: break-all;
   white-space: pre-wrap;
}
.selected {
   background-color: #007bff;
   color:white;
}

.signInButton {
   width: 75%;
   height: 35px;
   border-radius: 20px;
   background-color: white;
   color: #003daf;
   font-weight: bold;
}
.signInButton:hover {
   background-color: white;
   color: #003daf;
}

.signInText {
   color: white;
   margin-top: 21%;
   text-align: center;
}

.signInImage {
   border-radius: 110px;
   height: 16vh;
}
.signInImageContainer {
   position: absolute;
   margin-top: -42px;
}

.signInBox {
   height: 70vh;
   width: 30%;
   display: flex;
   flex-direction: column;
   align-items: center;
   background-color: #003daf;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   border-radius: 12px;
}

.signInContainer {
   height: 100vh;
   display: flex;
   align-items: center;
   background-color: white;
   justify-content: center;
}

.CommonModal{
   text-align: center;
   display: block;
   /* font-weight:1000; */
   color:black;
   font-weight: bolder;
   font-family:Century Gothic ;
}
.CommonModalBodyHead{
   justify-content: center;
   text-align: center;
   font-weight: 400;
   font-family:Century Gothic ;
}

.CommonModalFooter{
   color: white;
   font-weight: 900;
   font-family:Century Gothic ;
}
.vendorData{
   color: yellow;
   font-size: 14px;
   text-Align: center;
   font-Weight: bold;
   font-family: Century Gothic;
   margin-Top: 10px;
}
.VendorTable{
   text-Align: center;
   width: 100%;
   margin-Top: 5%;
   margin-Bottom: 5%;
}
.actionDetails{
   color:blue;
   text-Decoration:underline;
   cursor:pointer;
   text-align: left;
}