.container {
   padding-top: 5px;
   padding-left: 1px;
   padding-right: 1px;
   width: 100%;
   max-width: 100%;
}

.colWidth {
   width: 8%;
   word-wrap: break-word;
   word-break: break-all;
}
.colDiscWidth {
   width: 10%;
   word-wrap: break-word;
   word-break: break-all;
}
.pagination {
   display: flex;
   padding-left: 0;
   list-style: none;
   border-radius: 0.25rem;
}
.pull-right {
   float: right;
}
.pagination > li {
   display: inline;
}
ul {
   display: block;
   list-style-type: disc;
   margin-block-start: 1em;
   margin-block-end: 1em;
   margin-inline-start: 0px;
   margin-inline-end: 0px;
   padding-inline-start: 40px;
}

.col-xs-8 {
   width: 66.66666667%;
   position: relative;
   min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
}
.col-xs-4 {
   width: 33.33333333%;
   padding-left: 15px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
   margin-left: 0;
   border-top-left-radius: 4px;
   border-bottom-left-radius: 4px;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
   color: #777;
   cursor: not-allowed;
   background-color: #fff;
   border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
   z-index: 2;
   color: #fff;
   cursor: default;
   background-color: #337ab7;
   border-color: #337ab7;
}

.pagination > li > a,
.pagination > li > span {
   position: relative;
   float: left;
   padding: 6px 12px;
   margin-left: -1px;
   line-height: 1.42857143;
   color: #337ab7;
   text-decoration: none;
   background-color: #fff;
   border: 1px solid #ddd;
}

label {
   margin-right: 0.3rem;
}

.preserveSpace {
   width: 8%;
   word-wrap: break-word;
   word-break: break-all;
   white-space: pre-wrap;
}
