.list-item {
    cursor: pointer;
    /* transition: background-color 0.2s; */
  }
   
  .list-item:hover {
    background-color:#0173d4;
    color:whitesmoke;
    border: 1px solid;
  }
   
  .list-item.selected {
    background-color:#0173d4;
    /* color: white; */
  }
   
  .list-item.shift-select {
    background-color: initial;
    color: initial;
  }
  