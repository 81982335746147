html {
   font-size: 13px;
   overflow-y: scroll;
}

hr {
   border-width: 2px;
   margin-top: 1rem;
   margin-bottom: 1rem;
}
body {
   margin: 0;
   background-image: linear-gradient(90deg, #003daf, #003daf);
   line-height: 1.4;

   /* background-image: linear-gradient(90deg, #003daf, #003daf);  */
}
#root {
   font-family: Century Gothic;
}

/* BreadCrumb StyleSheet */
ul.breadcrumb {
   height: 30px;
   padding: 8px 10px;
   margin-bottom: 4px;
   list-style: none;
   /* background-color: #eee; */
   background-color: #09f;
}
ul.breadcrumb li {
   display: inline;
   font-size: 12px;
}
ul.breadcrumb li + li:before {
   padding: 6px;
   /* color: black; */
   color: white;
   content: "/\00a0";
}
ul.breadcrumb li a {
   /* color: #0275d8; */
   color: white;
   text-decoration: none;
}
ul.breadcrumb li a:hover {
   color: #01447e;
   text-decoration: underline;
}

/* transition for latantncy */
/* .page {
position: absolute;
top:100px;
left: 0;
right: 0;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-acitve,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 10ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
} */

.my-node-enter {
   opacity: 0;
}
.my-node-enter-active {
   opacity: 1;
   transition: opacity 500ms;
}
.my-node-exit {
   opacity: 1;
}
.my-node-exit-active {
   opacity: 0;
   transition: opacity 500ms;
}
